import React, {memo, useRef} from 'react';
import {useDispatch} from 'react-redux';

import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';

import {setWhereCallFrom} from '../../../_store/permission.slice';
import getUserAgent from '../../../utils/UserAgent';
import IconButton from '../../Button/IconButton/IconButton';
import styles from './UploadImg.module.scss';

let myImgUploadRef = null;
export const openImgLoadWindowUploadImg = () => {
    myImgUploadRef.current.click();
};

function UploadImg(props) {
    const {imgStringList, setImgStringList} = props;

    const dispatch = useDispatch();
    const imgUploadRef = useRef(null);
    myImgUploadRef = imgUploadRef;

    const ua = getUserAgent();

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
    });

    // 이미지를 base64로 변환하는 함수
    function convertImageToBase64(file) {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result;
            // 이미지가 변환될 때마다 기존 imgStringList에 추가
            setImgStringList((prevImgStringList) => [...prevImgStringList, base64Data]);
        };

        reader.readAsDataURL(file);
    }

    // 이미지가 선택되었을 때 호출되는 이벤트 핸들러
    const handleImageUpload = (event) => {
        const selectedFiles = event.target.files;

        if (selectedFiles.length > 0) {
            // 이미지 업로드시 이미지 첨부 10장까지 제한
            if (imgStringList.length + selectedFiles.length > 10) {
                window.alert('이미지는 10장까지 첨부 가능합니다.');
                return;
            }

            // 선택된 파일들을 base64로 변환하여 imgStringList에 추가
            for (const file of selectedFiles) {
                convertImageToBase64(file);
            }
        }
    };

    // 이미지 삭제
    const handleImageDelete = (index) => {
        const newStringImages = [...imgStringList];
        newStringImages.splice(index, 1);
        setImgStringList(newStringImages);
    };

    // 카메라 접근 권한 허용 여부 확인
    const photoPermissionCheck = () => {
        // 버튼 클릭시 이미지 첨부 10장까지 제한
        if (imgStringList.length >= 10) {
            window.alert('이미지는 10장까지 첨부 가능합니다.');
            return;
        }

        if (ua !== 'web') {
            dispatch(setWhereCallFrom('UploadImg'));
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'checkPhotoCameraPermission'
                })
            );
        } else {
            imgUploadRef.current.click();
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(imgStringList); // 원본 배열 복사
        const [removed] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, removed);
        setImgStringList(items);
    };

    const handleDragStart = (e) => {
        // 드래그 시작시 input focus 해제 : ios 버그 때문에 추가함
        const activeElement = document.activeElement;
        if (activeElement.tagName === 'INPUT') {
            activeElement.blur();
        }
    };

    return (
        <div className={styles.uploadImgArea}>
            <div className={styles.btnImgUpload}>
                <Button
                    component="label"
                    variant="outlined"
                    color="gray"
                    startIcon={<CameraAltIcon />}
                    className={styles.btnAddImg}
                    onClick={photoPermissionCheck}
                >
                    <p className={styles.num}>
                        <b>{imgStringList.length}</b>/10
                    </p>
                </Button>

                <VisuallyHiddenInput
                    ref={imgUploadRef}
                    onChange={handleImageUpload}
                    type="file"
                    accept="image/*"
                    multiple
                />
            </div>

            <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
                <Droppable
                    droppableId="droppable"
                    direction={imgStringList.length > 1 ? 'horizontal' : undefined}
                >
                    {(provided) => (
                        <ul ref={provided.innerRef} {...provided.droppableProps} className={styles.uploadImg}>
                            {imgStringList.length > 0 && (
                                <>
                                    {imgStringList?.map((list, i) => (
                                        <Draggable key={i} draggableId={i.toString()} index={i}>
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        position: 'relative',
                                                        ...provided.draggableProps.style
                                                    }}
                                                >
                                                    {provided?.placeholder}
                                                    <img
                                                        src={list}
                                                        width={120}
                                                        height={120}
                                                        alt={`상품등록 추가이미지${i}`}
                                                    />
                                                    <IconButton
                                                        name="delete_circle"
                                                        width="16"
                                                        height="16"
                                                        fill="#393A3F"
                                                        className={styles.btnImgDelete}
                                                        onClick={() => handleImageDelete(i)}
                                                    />
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                </>
                            )}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default memo(UploadImg);
