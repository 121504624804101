import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setLocDrawerOpen, setMapDragOn, setPlaceInfo} from '../../../_store/makePin.slice';
import {localStorageUtil} from '../../../utils/LocalStorage';
import getUserAgent from '../../../utils/UserAgent';
import {cityHallCoords} from '../../../utils/constant';
import IconButton from '../../Button/IconButton/IconButton';
import './SwipeMap.scss';

const {naver} = window;
let myMapState = null;

export const setCenter = (lat, lng) => {
    myMapState.setCenter(new naver.maps.LatLng(lat, lng)); // 지도 센터 포지션으로 이동
};

function SwipeMap(props) {
    const dispatch = useDispatch();
    const {placeInfo, mapDragOn, locDrawerOpen} = useSelector((state) => state.makePin);

    const [myMap, setMyMap] = useState(null);
    myMapState = myMap;

    const [latitude, setLatitude] = useState(locDrawerOpen.lat); // 위도
    const [longitude, setLongitude] = useState(locDrawerOpen.lng); // 경도

    const mapRef = useRef(null);

    // 좌표를 주소로 변환
    function searchCoordinateToAddress(latlng) {
        naver.maps.Service.reverseGeocode(
            {
                coords: latlng,
                orders: [naver.maps.Service.OrderType.ADDR, naver.maps.Service.OrderType.ROAD_ADDR].join(',')
            },
            function (status, response) {
                if (status === naver.maps.Service.Status.ERROR) {
                    return alert('Something Wrong!');
                }

                const result = response.v2;
                const items = result.results;
                const address = result.address;

                const updatePlaceInfoState = {
                    ...placeInfo,
                    addr: address.roadAddress,
                    addrStreet: address.jibunAddress,
                    lat: latlng._lat,
                    lng: latlng._lng
                };
                dispatch(setPlaceInfo(updatePlaceInfoState));
            }
        );
    }

    useEffect(() => {
        // Naver Map API가 window 객체에서 사용 가능한지 확인
        if (!naver || !mapRef.current) {
            console.error('Naver Map API가 로드되지 않았습니다.');
            return;
        }

        setMyMap(
            new naver.maps.Map(mapRef.current, {
                center: new naver.maps.LatLng(latitude, longitude),
                width: '100%',
                height: '100%',
                zoom: 16
            })
        );
        searchCoordinateToAddress(new naver.maps.LatLng(latitude, longitude));

        return () => {
            // Cleanup any resources if necessary
            setMyMap(null);
        };
    }, []);

    useEffect(() => {
        if (myMap !== null) {
            naver.maps.Event.addListener(myMap, 'dragstart', () => {
                // 드래그 시작
                dispatch(setMapDragOn(true));
            });

            naver.maps.Event.addListener(myMap, 'dragend', () => {
                // 드래그 종료
                dispatch(setMapDragOn(false));
                const center = myMap.getCenter(); // 지도의 중심 좌표 가져오기
                searchCoordinateToAddress(center); // 좌표를 주소로 변환

                // console.log('드래그 종료 - 중심 좌표:', center.toString());
                // console.log('위도:', center.lat(), '경도:', center.lng());
            });
        }
    }, [myMap]);

    // 맵의 우측 하단 현재 위치로 설정 버튼 클릭 (현재 위치로 지도 이동)
    const handleCurrentLocation = () => {
        const ua = getUserAgent();

        if (ua === 'web') {
            const center = new naver.maps.LatLng(cityHallCoords.lat, cityHallCoords.lng);
            myMap.setCenter(center); // 지도 센터 포지션으로 이동
            searchCoordinateToAddress(center); // 좌표를 주소로 변환
        } else {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({type: 'gpsPermissonCheck', whareCalled: 'swpieMap'})
            );
        }
    };

    return (
        <div id="swipeMap">
            <div className={`centerPos ${mapDragOn ? 'dragOn' : ''}`}>
                <span className="txt">지도를 움직여 위치를 설정하세요</span>
                <img className="mainPinMarker" src={localStorageUtil.get('mainPinIcon')}></img>
                {/* <Icon name="wepin_marker" width="41" height="53" fill="#297FFF" /> */}
            </div>
            <div id="map_div" ref={mapRef}></div>
            <IconButton
                name="current_gps"
                width="56"
                height="56"
                fill="#297FFF"
                className="btnCurrentPos"
                onClick={handleCurrentLocation}
            />
        </div>
    );
}

export default SwipeMap;
